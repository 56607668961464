.react-datepicker-popper {
  z-index: 100 !important;
}

.react-datepicker-wrapper {
  width: auto !important;
}

.page {
  height: calc(100vh - 54px);
  overflow: auto;
}

.page-nav-toc {
  align-self: flex-start;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.page-nav-sidebar {
  align-self: flex-start;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

@media (max-width: 992px) {
  .page-nav-sidebar {
    position: initial !important;
  }
}

.modal-90-percent {
  width: 90%;
  max-width: none !important;
}

.modal-95-percent {
  width: 95%;
  max-width: none !important;
}

.modal-100-percent {
  width: 100%;
  max-width: none !important;
}

/* Modal Stack to show above others */
.modal2-backdrop.show {
  z-index: 1140;
}
.modal2.show {
  z-index: 1150;
}

/* Popover Stack to show above others */
.popover2 {
  z-index: 1150;
}
